import Cookies from 'js-cookie'
import Avatar from '@coreComponents/avatar'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

export const formatPhone = (value) => {
    value = value.replace(/\D/g, '')
    if (value.substring(0, 2) === '55') value = value.substring(2, 13)
    return value.length >= 11 ? `(${value.substring(0, 2)}) ${value.substring(2, 7)}-${value.substring(7, 11)}` : value
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = { month: 'short', day: 'numeric' }

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
    if (Cookies.get('userToken')) return true
    return false
}
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/'
    if (userRole === 'client') return '/access-control'
    return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const normalizeField = (doc) => {
    return doc.replace(/\D/g, '')
}

// ** Masks
export const maskCpfCnpj = (doc) => {
    let block1, block2, block3, block4, block5
    doc = normalizeField(doc)
    if (doc.length === 11) {
        doc = new Array(11 - doc.length + 1).join('0') + doc
        block1 = doc.substring(0, 3)
        block2 = doc.substring(3, 6)
        block3 = doc.substring(6, 9)
        block4 = doc.substring(9)
        return `${block1}.${block2}.${block3}-${block4}`
    } else if (doc.length === 14) {
        doc = new Array(14 - doc.length + 1).join('0') + doc
        block1 = doc.substring(0, 2)
        block2 = doc.substring(2, 5)
        block3 = doc.substring(5, 8)
        block4 = doc.substring(8, 12)
        block5 = doc.substring(12)
        return `${block1}.${block2}.${block3}/${block4}-${block5}`
    } else return doc
}

export const validateCpfCnpj = (doc) => {
    doc = normalizeField(doc)
    if (doc.length === 11) {
        let soma = 0, resto
        if (doc === "00000000000") return false

        for (let i = 1; i <= 9; i++) soma += parseInt(doc.substring(i - 1, i)) * (11 - i)
        resto = (soma * 10) % 11

        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(doc.substring(9, 10))) return false

        soma = 0
        for (let i = 1; i <= 10; i++) soma += parseInt(doc.substring(i - 1, i)) * (12 - i)
        resto = (soma * 10) % 11

        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(doc.substring(10, 11))) return false
        return true
    } else if (doc.length === 14) {
        // Guarda um array com todos os dígitos do valor
        const match = doc.toString().match(/\d/g)
        const numbers = Array.isArray(match) ? match.map(Number) : []

        // Valida a quantidade de dígitos
        if (numbers.length !== 14) return false

        // Elimina inválidos com todos os dígitos iguais
        const items = [...new Set(numbers)]
        if (items.length === 1) return false

        // Cálculo validador
        const calc = (x) => {
            const slice = numbers.slice(0, x)
            let factor = x - 7
            let sum = 0

            for (let i = x; i >= 1; i--) {
                const n = slice[x - i]
                sum += n * factor--
                if (factor < 2) factor = 9
            }

            const result = 11 - (sum % 11)

            return result > 9 ? 0 : result
        }

        // Separa os 2 últimos dígitos de verificadores
        const digits = numbers.slice(12)

        // Valida 1o. dígito verificador
        const digit0 = calc(12)
        if (digit0 !== digits[0]) return false

        // Valida 2o. dígito verificador
        const digit1 = calc(13)
        return digit1 === digits[1]
    } else return false
}

export const maskMoney = value => {
    return value
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export const formatUserAbility = (userData) => {
    let ability = [
        {
            action: 'read',
            subject: 'home'
        }
    ]
    if (userData && userData.menus) {
        ability = ability.concat(userData.menus.flatMap(menu => ([
            {
                action: 'read',
                subject: menu.ds_link
            },
            {
                action: 'create',
                subject: menu.ds_link
            },
            {
                action: 'edit',
                subject: menu.ds_link
            },
            {
                action: 'delete',
                subject: menu.ds_link
            }
        ])))
        return ability
    }
    return null
}

/*eslint-disable */
export const flatten = (obj, roots = [], sep = '.') => Object
    .keys(obj)
    .reduce((memo, prop) => Object.assign(
        {},
        memo,
        Object.prototype.toString.call(obj[prop]) === '[object Object]'
            ? flatten(obj[prop], roots.concat([prop]))
            : { [roots.concat([prop]).join(sep)]: obj[prop] }
    ), {})

export const formatDateFilter = (input) => {
    const datePart = input.match(/\d+/g),
        year = datePart[0].substring(),
        month = datePart[1], day = datePart[2]

    return `${day}-${month}-${year}`
}

// ** Renders Partner Column
export const renderClient = row => {
    const stateNum = Math.floor(Math.random() * 6),
        states = ['light-success', 'light-danger', 'light-warning', 'light-info', 'light-primary', 'light-secondary'],
        color = states[stateNum]

    return <Avatar color={color || 'primary'} className='mr-1' content={row.no_usuario} initials contentStyles={{ minWidth: '0px' }} />
}

export const getUtcDateTime = (offset) => {
    var d = new Date()
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000)
    var nd = new Date(utc + (3600000 * offset))
    return nd
}
