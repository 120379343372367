// ** Initial State
const initialState = {
    data: [],
    loading: false,
    menus: [],
    error: false
}

const roles = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ROLES':
            return {
                ...state,
                loading: true
            }
        case 'GET_ROLES_SUCCESS':
            return {
                ...state,
                data: action.payload.data,
                loading: false
            }
        case 'GET_ROLES_FAILED':
            return {
                ...state,
                loading: false
            }

        case 'GET_MENUS':
            return {
                ...state,
                loading: true
            }
        case 'GET_MENUS_SUCCESS':
            return {
                ...state,
                menus: action.payload.data,
                loading: false
            }
        case 'GET_MENUS_FAILED':
            return {
                ...state,
                loading: false
            }

        case 'UPDATE_ROLE':
            return { ...state, loading: true }
        case 'UPDATE_ROLE_SUCCESS':
            return { ...state, loading: false }
        case 'UPDATE_ROLE_FAILED':
            return { ...state, loading: false }

        case 'SET_ERROR':
            return { ...state, error: action.payload.error }

        case 'ADD_ROLE':
            return { ...state, loading: true }
        case 'ADD_ROLE_SUCCESS':
            return { ...state, loading: false }
        case 'ADD_ROLE_FAILED':
            return { ...state, loading: false, error: action.payload.error }

        case 'DELETE_ROLE':
            return { ...state, loading: true }
        case 'DELETE_ROLE_SUCCESS':
            return { ...state, loading: false }
        case 'DELETE_ROLE_FAILED':
            return { ...state, loading: false }
        default:
            return state
    }
}
export default roles
