// ** Initial State
const initialState = {
    data: [],
    total: 0,
    loading: false,
    filtros: {},
    dataToExport: []
}

const boletos = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BOLETOS':
            return { ...state, loading: true }
        case 'GET_BOLETOS_SUCCESS':
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total,
                loading: false
            }
        case 'GET_BOLETOS_DOWNLOAD_SUCCESS':
            return {
                ...state,
                dataToExport: action.payload.data,
                total: action.payload.total,
                loading: false
            }
        case 'GET_BOLETOS_FAILED':
            return { ...state, loading: false }
        default:
            return { ...state }
    }
}

export default boletos
