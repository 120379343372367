// ** Initial State
const initialState = {
    data: [],
    total: 1,
    selectedUser: {},
    loading: false,
    error: false,
    dataToExport: []
}

const users = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_USERS':
            return {
                ...state,
                loading: true
            }
        case 'GET_USERS_SUCCESS':
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total,
                loading: false,
                error: false
            }
        case 'GET_USERS_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }

        case 'GET_USER':
            return {
                ...state,
                loading: true
            }
        case 'GET_USER_SUCCESS':
            return { ...state, selectedUser: action.payload.selectedUser, loading: false }
        case 'GET_USER_FAILED':
            return { ...state, loading: false }

        case 'ADD_USER':
            return { ...state, loading: true }
        case 'ADD_USER_SUCCESS':
            return { ...state, loading: false }
        case 'ADD_USER_FAILED':
            return { ...state, loading: false }

        case 'DELETE_USER':
            return { ...state, loading: true }
        case 'DELETE_USER_SUCCESS':
            return { ...state, loading: false }
        case 'DELETE_USER_FAILED':
            return { ...state, loading: false }

        case 'UPDATE_USER':
            return { ...state, loading: true }
        case 'UPDATE_USER_SUCCESS':
            return { ...state, loading: false }
        case 'UPDATE_USER_FAILED':
            return { ...state, loading: false }

        case 'SET_SELECTED_USER':
            return { ...state, selectedUser: action.payload.selectedUser }

        case 'GET_USERS_DOWNLOAD_SUCCESS':
            return {
                ...state,
                dataToExport: action.payload.data,
                total: action.payload.total,
                loading: false,
                error: false
            }
        default:
            return { ...state }
    }
}
export default users
