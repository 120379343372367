// ** Initial State
const initialState = {
    data: [],
    total: 0,
    selectedPartner: {},
    loading: false,
    error: false,
    dataToExport: [],
    payments: []
}

const partners = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PARTNERS':
            return { ...state, loading: true }
        case 'GET_PARTNERS_SUCCESS':
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total,
                loading: false
            }
        case 'GET_PAYMENTS_SUCCESS':
            return {
                ...state,
                payments: action.payload.payments,
                loading: false
            }
        case 'GET_PAYMENTS_FAILED':

            return { ...state, loading: false }
        case 'GET_PAYMENTS':
            return { ...state, loading: true }
        case 'GET_PARTNERS_DOWNLOAD_SUCCESS':
            return {
                ...state,
                dataToExport: action.payload.data,
                total: action.payload.total,
                loading: false
            }

        case 'GET_PARTNERS_FAILED':

            return { ...state, loading: false }
        case 'GET_PARTNER':
            return { ...state, loading: true }
        case 'GET_PARTNER_SUCCESS':
            return {
                ...state,
                selectedPartner: action.payload.selectedPartner,
                loading: false
            }
        case 'GET_PARTNER_FAILED':
            return { ...state, loading: false }

        case 'UPDATE_PARTNER':
            return { ...state, loading: true }
        case 'UPDATE_PARTNER_SUCCESS':
            return { ...state, loading: false }
        case 'UPDATE_PARTNER_FAILED':
            return { ...state, loading: false }

        case 'ADD_PARTNER':
            return { ...state, loading: true }
        case 'ADD_PARTNER_SUCCESS':
            return { ...state, loading: false }
        case 'ADD_PARTNER_FAILED':
            return { ...state, loading: false }

        case 'DELETE_PARTNER':
            return { ...state, loading: true }
        case 'DELETE_PARTNER_SUCCESS':
            return { ...state, loading: false }
        case 'DELETE_PARTNER_FAILED':
            return { ...state, loading: false }

        case 'MANAGE_BALANCE':
            return { ...state, loading: true }
        case 'MANAGE_BALANCE_SUCCESS':
            return { ...state, loading: false }
        case 'MANAGE_BALANCE_FAILED':
            return { ...state, loading: false }

        case 'SET_SELECTED_PARTNER':
            return { ...state, selectedPartner: action.payload.selectedPartner }

        case '2_VIA_BOLETO':
            return { ...state, loading: true }
        case '2_VIA_BOLETO_SUCCESS':
            return { ...state, loading: false }
        case '2_VIA_BOLETO_FAILED':
            return { ...state, loading: false }

        default:
            return state
    }
}

export default partners
