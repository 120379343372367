// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import roles from './roles'
import partners from './partners'
import users from './users'
import recharges from './recharges'
import movimentacoes from './movimentacoes'
import historico from './historico'
import boletos from './boletos'
import plans from './plans'

const rootReducer = combineReducers({
    auth,
    navbar,
    users,
    recharges,
    partners,
    movimentacoes,
    roles,
    layout,
    historico,
    boletos,
    plans
})

export default rootReducer
