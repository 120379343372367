// ** Initial State
const initialState = {
    planos: [],
    bonus: [],
    totalPlanos: 0,
    totalBonus: 0,
    loading: false,
    error: false
}

const plans = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PLANS':
            return { ...state, loading: true }
        case 'GET_PLANS_SUCCESS':
            return {
                ...state,
                planos: action.payload.planos,
                totalPlanos: action.payload.total,
                loading: false
            }
        case 'GET_PLANS_FAILED':
            return {
                ...state,
                loading: false
            }

        case 'GET_BONUS':
            return { ...state, loading: true }
        case 'GET_BONUS_SUCCESS':
            return {
                ...state,
                bonus: action.payload.bonus,
                totalBonus: action.payload.total,
                loading: false
            }
        case 'GET_BONUS_FAILED':
            return {
                ...state,
                loading: false
            }

        case 'EDIT_PLAN':
            return { ...state }
        case 'EDIT_PLAN_SUCCESS':
            return { ...state }
        case 'EDIT_PLAN_FAILED':
            return { ...state }

        default:
            return state
    }
}

export default plans
