// ** Initial State
const initialState = {
    data: [],
    total: 0,
    selectedRecharge: {},
    loading: false,
    filtros: {
        mvnos: [],
        sub_mvnos: [],
        ddds: [],
        revendedores: []
    },
    dataToExport: []
}

const recharges = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_RECHARGE':
            return { ...state, selectedRecharge: action.payload.selectedRecharge }

        case 'GET_RECHARGES':
            return { ...state, loading: true }
        case 'GET_RECHARGES_SUCCESS':
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total,
                loading: false
            }
        case 'GET_RECHARGES_DOWNLOAD_SUCCESS':
            return {
                ...state,
                dataToExport: action.payload.data,
                total: action.payload.total,
                loading: false
            }
        case 'GET_RECHARGES_FAILED':
            return { ...state, loading: false }

        case 'GET_FILTERS':
            return { ...state, loading: true }
        case 'GET_FILTERS_SUCCESS':
            return {
                ...state,
                filtros: {
                    mvnos: action.payload.data.mvnos,
                    sub_mvnos: action.payload.data.sub_mvnos,
                    ddds: action.payload.data.ddds,
                    revendedores: action.payload.data.revendedores
                },
                loading: false
            }
        case 'GEt_FILTERS_FAILED':
            return { ...state, loading: false }

        case 'GET_STATES':
            return { ...state, loading: true }
        case 'GET_STATES_SUCCESS':
            return {
                ...state,
                states: action.payload.data,
                loading: false
            }
        case 'GET_STATES_FAILED':
            return { ...state, loading: false }

        case 'GET_RECHARGE':
            return { ...state, selectedRecharge: action.payload.selectedRecharge }
        default:
            return { ...state }
    }
}
export default recharges
