// **  Initial State
const initialState = {
    userData: {},
    loginError: false,
    error: false,
    loading: false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                loading: true
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        case 'LOGIN_FAILED':
            return {
                ...state,
                ...action.payload,
                loading: false,
                erro: true
            }
        case 'LOGOUT':
            const obj = { ...action }
            delete obj.type
            return { ...state, userData: {}, ...obj }
        case 'GET_USER_DATA':
            return {
                ...state,
                loading: true
            }
        case 'GET_USER_DATA_SUCCESS':
            return {
                ...state,
                userData: action.payload.userData,
                loading: false
            }
        case 'GET_USER_DATA_FAILED':
            return {
                ...state,
                userData: action.payload.userData,
                loading: false
            }
        default:
            return state
    }
}

export default authReducer
